export type Language = {
    code: string, localeCode: string,
    caption: string, img: string
}


export const languages: Language[] = [
    {
        code: 'cs', localeCode: 'cs_CZ', caption: 'Čeština',
        img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAFuSURBVHjaYmSIP9sdIJLlJsIABv/+/YOQyAw4+PPvH0AAsQj++8LGK3vzxV8DRR6Inv//wQScQgIvXrwACCAmIOD+/2f1hS9Hrn+EqIArQzMeYiFAALEAGX///ef6+3PjlX9///2z0xL4jwNANAAEEMsfoLuAhv3/z/3357qLf//8/eegLYBLw58/fwACiOXXH7Bv/v7/+/c/59+fK87+Fd++WkmUDcj/9+cPkGT4C5b7+5dRQIAhLw8ggIA2AA0FCQL1/P77z/fCGplzG37++v3/16+/v0Hk/9+//4FJFiWlDz9/AgQQy98/QKeDlAI12J3faHduI1TR798Mv34xgPUASaBrQIiBASCAWBh+gawAqT630f48SDXEPLg2kMgfoAaQq4A6AAIIqAGk2uvZEdO35xmkpIFyTEDzgEqBHvj9mxFI/vnDDPMG0AaAAGJh+PHnWohxCMP7l8BQAyNQaMDYfyDRD2MwMTAABBgAMxl5E8UTSgsAAAAASUVORK5CYII=',
    },
    {
        code: 'en', localeCode: 'en_US', caption: 'English',
        img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAHzSURBVHjaYkxOP8IAB//+Mfz7w8Dwi4HhP5CcJb/n/7evb16/APL/gRFQDiAAw3JuAgAIBEDQ/iswEERjGzBQLEru97ll0g0+3HvqMn1SpqlqGsZMsZsIe0SICA5gt5a/AGIEarCPtFh+6N/ffwxA9OvP/7//QYwff/6fZahmePeB4dNHhi+fGb59Y4zyvHHmCEAAAW3YDzQYaJJ93a+vX79aVf58//69fvEPlpIfnz59+vDhw7t37968efP3b/SXL59OnjwIEEAsDP+YgY53b2b89++/awvLn98MDi2cVxl+/vl6mituCtBghi9f/v/48e/XL86krj9XzwEEEENy8g6gu22rfn78+NGs5Ofr16+ZC58+fvyYwX8rxOxXr169fPny+fPn1//93bJlBUAAsQADZMEBxj9/GBxb2P/9+S/R8u3vzxuyaX8ZHv3j8/YGms3w8ycQARmi2eE37t4ACCDGR4/uSkrKAS35B3TT////wADOgLOBIaXIyjBlwxKAAGKRXjCB0SOEaeu+/y9fMnz4AHQxCP348R/o+l+//sMZQBNLEvif3AcIIMZbty7Ly6t9ZmXl+fXj/38GoHH/UcGfP79//BBiYHjy9+8/oUkNAAHEwt1V/vI/KBY/QSISFqM/GBg+MzB8A6PfYC5EFiDAABqgW776MP0rAAAAAElFTkSuQmCC',
    },
]
