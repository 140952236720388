import {RouteLocationNormalizedLoaded} from "vue-router"
import {computed, Ref, watch} from "vue"
import appPages, {AppPage, appPageIndex} from "@/modules/App/appPages"

export function useRouteTitle(route: RouteLocationNormalizedLoaded) {
    const title = computed(() => {
        let title = route.meta.title
        if (typeof title === "function") {
            title = title(route.params)
        }
        return title
    })

    watch(title, (title) => document.title = 'MKV - ' + (title || 'Ozubená kola'), {immediate: true})

    return title
}

export function useRouteBreadcrumbs(route: RouteLocationNormalizedLoaded) {
    return computed(() => {
        const locale = route.params.locale as string || 'cs'
        if (!route.params.page) {
            return null
        }

        const page = appPages.findPage(locale, route.params.page as string)
        if (!page) {
            return null
        }

        const pageParams: {[param: string]: any} = {}
        if (locale && locale !== 'cs') {
            pageParams.locale = locale
        }

        const crumbs = []
        for (let p: AppPage|undefined = page; p; p = p.parent ? appPageIndex[p.parent] : undefined) {
            crumbs.unshift({
                to: {name: 'Page', params: {...pageParams, page: p.fullPaths![locale]}},
                text: p.titles[locale]
            })
        }
        crumbs.unshift({to: {name: 'Home'}, text: 'MKV'})

        return crumbs
    })
}

export function createPageRouteGenerator(locale: Ref<string>) {
    return (page: string) => {
        const appPage = appPageIndex[page]
        if (!appPage) {
            console.warn('No server page', page)
            return {to: {name: 'Home'}, text: 'MKV'}
        }

        const localeCode = locale.value

        let pageUrl = appPage.slugs[localeCode]
        if (appPage.parent) {
            const parent = appPageIndex[appPage.parent]
            pageUrl = parent.slugs[localeCode] + '/' + pageUrl
        }

        const params = {
            page: pageUrl,
        } as any

        if (localeCode !== 'cs') {
            params.locale = localeCode
        }

        return {
            text: appPage.titles[localeCode]!,
            to: {name: 'Page', params}
        }
    }
}

export function getCurrentPage(route: RouteLocationNormalizedLoaded): AppPage|undefined {
    return appPages.findPage((route.params.locale as string) || 'cs', route.params.page as string)
}
