import {computed, defineComponent, h, nextTick, onMounted, shallowRef, watch} from "vue"
import appPages, {AppPage} from "@/modules/App/appPages";

import baguetteBox from 'baguettebox.js';
import "baguettebox.js/dist/baguetteBox.css";
import {useTranslator} from "@/modules/App/i18n"


export default defineComponent({
    props: {
        locale: {type: String},
        page: {type: String, required: true},
    },
    setup(props) {
        const t = useTranslator()

        const currentPage = shallowRef<AppPage|null>(null)

        const content = shallowRef<any>(null)
        const appPage = computed(() => {
            if (!props.locale || !props.page) {
                return null
            }

            return props.page.charAt(0) === '@'
                ? appPages.findPageByName(props.page.substr(1))
                : appPages.findPage(props.locale, props.page)
        })

        watch(() => [props.locale, appPage.value], ([locale, appPage]) => {
            const foundPage = appPage as AppPage
            const pageChanged = currentPage.value !== foundPage
            currentPage.value = foundPage

            if (!foundPage || typeof locale !== "string") {
                content.value = null
                return
            }

            if (foundPage.component) {
                if (typeof foundPage.component === "function" && pageChanged) {
                    content.value = null
                    foundPage.component()
                        .then((module: any) => foundPage.component = module.default)
                        .then((component: any) => content.value = {component})
                } else {
                    content.value = {component: foundPage.component}
                }

                return
            }

            content.value = null
            appPages.fetchPage(locale, foundPage)
                .then((pageContent) => content.value = {html: pageContent})
                .catch((error) => {
                    content.value = {html: 'Stránku nelze načíst. <br/>Můžete se <a href="/">vrátit na úvodní stránku</a>.'}
                    throw error
                })
        }, {immediate: true})

        function initGalleries() {
            baguetteBox.run('.image-gallery, .bb-preview')
        }
        function clearContent() {}

        onMounted(() => {
            watch(content, (content) => {
                nextTick(() => content ? initGalleries() : clearContent())
            }, {immediate: true})
        })

        return () => {
            if (!content.value) {
                // TODO: Improve loader
                return h('div', [
                    t('generic.loading'),
                ])
            }

            if (content.value.component) {
                return h(content.value.component, {
                    locale: props.locale,
                })
            }

            let className = 'page page-content'
            if (appPage.value?.class) {
                className += ' ' + appPage.value.class
            }

            return h('div', {
                class: className,
                innerHTML: content.value.html,
            })
        }
    },
})
