
import {computed, defineComponent, h, ref, watch} from "vue";
import {useRoute} from "vue-router";

import BaseLayout from "@/modules/App/Layout/BaseLayout.vue";
import {bottomMenuLinks, topMenuLinks} from "@/modules/App/navigationStore";
import {provideRouteGenerator} from "@/modules/Navigation/navRouting";
import {useLocaleSwitch} from "@/modules/App/appLocalization";
import {createPageRouteGenerator, getCurrentPage, useRouteBreadcrumbs, useRouteTitle} from "@/modules/App/traits/appRouting"
import {createLocalization, provideLocalization} from "@/modules/App/i18n"


export default defineComponent({
  components: {
    BaseLayout,
  },
  setup() {
    const route = useRoute()
    const locale = computed<string>(() => route.params.locale as string || 'cs')
    const localeLoading = ref(false)
    const currentPageSlug = computed(() => route.params.page as string || '')

    const localeSwitch = useLocaleSwitch(locale, currentPageSlug)

    provideRouteGenerator(createPageRouteGenerator(locale))

    const title = useRouteTitle(route)
    const breadCrumbs = useRouteBreadcrumbs(route)
    const currentPage = computed(() => getCurrentPage(route))

    const appLocalization = createLocalization()
    const changeLocale = (code: string) => {
      const res = appLocalization.loadLocale(code)
      if (res) {
        localeLoading.value = true
        res.then(() => localeLoading.value = false)
      }
    }
    watch(locale, changeLocale, {immediate: true})
    provideLocalization(appLocalization)

    return () => h(BaseLayout as any, {
      navLinks: topMenuLinks.value,
      localeTarget: localeSwitch.localeTarget,
      footerLinks: bottomMenuLinks.value,
      pageTitle: title.value,
      currentPage: currentPage.value,
      breadCrumbs: breadCrumbs.value,

      locale: localeLoading.value ? undefined : locale.value,
    })
  },
})
