<template>
  <div class="contact-location">
    <div class="title">{{ title }}</div>
    <div v-if="mapLocation && addressOverlay" class="overlay address-overlay">
      <Address v-bind="addressOverlay"/>
    </div>

    <MapLocation v-if="mapLocation" v-bind="mapLocation" />

    <Address v-if="address" v-bind="address"/>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import Address from "./Address";
import MapLocation from "./MapLocation";

export default defineComponent({
  components: {
    MapLocation,
    Address,
  },
  props: {
    title: String,
    addressOverlay: Object,
    address: Object,
    mapLocation: Object,
  },
})
</script>

<style lang="scss">
.contact-location {
  position: relative;

  .title {
    font-weight: bold;
    margin-block-end: 0.75em;
  }

  .address-overlay {
    position: absolute;
    padding: 0.3em 0.425em;
    top: 2.7rem;
    right: 0.5rem;
    line-height: 1;
  }

  iframe + .address {
    margin-top: 0.5em;
  }
}
</style>