<template>
  <button class="navigation-toggle" v-if="toggleCaption !== undefined" @click="open = !open">
    <i class="fa-stack">
      <i class="fa fa-square-o fa-stack-2x"/>
      <i class="fa fa-navicon fa-stack-1x"/>
    </i>
    <span class="caption">{{ toggleCaption }}</span>
  </button>

  <nav role="navigation" :class="open ? 'open' : ''" v-bind="$attrs">
    <ul>
      <slot name="initial-items"/>
      <NavBranch v-for="link in links" :key="link.page" :link="link" :current-page="currentPage"/>

      <slot name="additional-items"/>
    </ul>
  </nav>
</template>

<script lang="ts">

import {defineComponent, PropType} from "vue";
import {NavLink} from "../App/navigationStore";
import NavBranch from "@/modules/Navigation/NavBranch.vue";
import {AppPage} from "@/modules/App/appPages"

export default defineComponent({
  inheritAttrs: false,
  components: {NavBranch},
  props: {
    links: {type: Array as PropType<NavLink[]>},
    toggleCaption: {type: String},
    currentPage: {type: Object as PropType<AppPage>},
  },
  data: () => ({
    open: false,
  }),
})

</script>
