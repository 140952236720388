<template>
  <header id="site-header">
    <router-link :to="{name: 'Home'}" rel="home" class="nav-brand">
      <img src="/images/mkv/logo_rgb_white.svg" alt="MKV logo">
    </router-link>

    <NavTree :links="navLinks" :current-page="currentPage" toggle-caption="Nabídka">
      <template #additional-items>
        <li v-if="localeTarget">
          <router-link :to="localeTarget.to" :hreflang="localeTarget.language.localeCode" :lang="localeTarget.language.localeCode" class="nav-item nav-item-locale">
            <img :src="localeTarget.language.img" :alt="localeTarget.language.caption" style="width: 16px; height: 11px;">
            <span style="margin-left:0.3em;">{{ localeTarget.language.caption }}</span>
          </router-link>

        </li>
      </template>
    </NavTree>
  </header>

  <div id="content">
    <div class="read-container">
      <main>
        <div class="content-navigation" v-if="pageTitle || breadCrumbs">
          <h1 class="title" v-if="pageTitle">{{ pageTitle }}</h1>
          <Breadcrumbs :crumbs="breadCrumbs" v-if="breadCrumbs"/>
        </div>

        <router-view v-bind="$attrs" />
      </main>
    </div>

    <aside>
      <ContactBar @open-contact-form="$emit('open-contact-form')"/>
    </aside>
  </div>

  <footer>
    <NavTree :links="footerLinks" class="footer-navigation">
      <template #initial-items>
        <li>
          <a href="/files/documents/vseobecne_podminky/MKV_vseobecne_podminky_kupujici.pdf" target="_blank">{{ t('document.tos') }}</a>
        </li>
      </template>
    </NavTree>

    <span class="copyright">Copyright {{ copyright.year }} {{ copyright.holder }}</span>
  </footer>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import Breadcrumbs from "@/modules/App/Layout/Breadcrumbs.vue";
import ContactBar from "@/modules/App/Layout/ContactBar.vue";
import companyStore from "@/modules/Directory/companyStore";
import {NavLink} from "@/modules/App/navigationStore";
import NavTree from "@/modules/Navigation/NavTree.vue";
import {LocaleTarget} from "@/modules/App/appLocalization";
import {useTranslator} from "@/modules/App/i18n"
import {AppPage} from "@/modules/App/appPages"

export default defineComponent({
  emits: {
    openContactForm: () => true,
  },
  components: {
    NavTree,
    ContactBar,
    Breadcrumbs,
  },
  inheritAttrs: false,
  props: {
    navLinks: {type: Array as PropType<NavLink[]>},
    localeTarget: {type: Object as PropType<LocaleTarget>},
    footerLinks: {type: Array as PropType<NavLink[]>},

    breadCrumbs: {type: Array},
    pageTitle: String,
    currentPage: {type: Object as PropType<AppPage>},
  },
  setup() {
    const t = useTranslator()

    const copyright = ref({
      year: (new Date()).getFullYear(),
      holder: companyStore.headquarters.value.name,
    })

    return {
      copyright,

      t,
    }
  },
})
</script>
