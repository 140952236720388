import {RouteLocationRaw} from "vue-router";
import {Language, languages} from "@/modules/App/localization";
import {computed, reactive, Ref} from "vue";
import appPages from "@/modules/App/appPages";

export type LocaleTarget = { to: RouteLocationRaw, language: Language }

export const useLocaleSwitch = (locale: Ref<string>, currentPage: Ref<string>) => {
    const localeTarget = computed<LocaleTarget>((): LocaleTarget => {
        const otherLanguage = locale.value === 'cs' ? languages[1] : languages[0]

        if (currentPage.value) {
            const targetPage = appPages.findPage(locale.value, currentPage.value)
            if (targetPage) {
                const to: RouteLocationRaw = {name: 'Page', params: {page: targetPage.fullPaths![otherLanguage.code]}}
                if (otherLanguage.code !== 'cs') {
                    to.params!.locale = otherLanguage.code
                }
                return {to, language: otherLanguage}
            }

            console.warn(`Page ${locale.value}:${currentPage.value} not found`)
        }

        if (locale.value !== 'cs') {
            return {
                to: {name: 'Home'},
                language: languages[0],
            }
        }

        return {
            to: {name: 'Home', params: {locale: 'en'}},
            language: languages[1],
        }
    })

    return reactive({
        localeTarget,
    })
}
