<template>
  <div class="address">
    <div class="line line-street">
      <span class="street">{{ street }}</span> <span class="number">{{ number }}</span>
    </div>
    <div class="line line-city">
      <span class="zip">{{ zip }}</span>, <span class="city">{{ city }}</span>
    </div>
    <div class="line line-country">
      <span class="country">{{ country }}</span>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  inheritAttrs: false,
  props: {
    street: String,
    number: String,
    zip: String,
    city: String,
    country: String,
  }
})
</script>