import {reactive, watch} from "vue";

type CarouselEngineOptions = {
    slideDuration?: number,
    bindActiveSlide?: () => number | undefined,
    onChange?: (slide: number) => void,
}

export type CarouselEngine = {
    slideCount: number,
    activeSlide: number,

    slideDuration: number,

    currentSlideRemainingDuration: number,

    start(): void,
    stop(): void,
    destroy(): void,
    setActiveItem(n: number, previewDuration?: number): void,
}

export const useCarouselEngine = (options: CarouselEngineOptions = {}): CarouselEngine => {
    let interval: ReturnType<typeof setInterval> = -1

    const tick = () => {
        engine.currentSlideRemainingDuration -= 500
        if (engine.currentSlideRemainingDuration <= 0) {
            engine.setActiveItem(engine.activeSlide + 1)
        }
    }

    const engine: CarouselEngine = reactive({
        slideCount: 0,
        activeSlide: -1,

        slideDuration: options.slideDuration || 2000,
        currentSlideRemainingDuration: -1,

        start() {
            if (engine.slideCount < 1) {
                console.error("Carousel has no slides")
                return
            }
            if (interval !== -1) {
                console.warn("Carousel already running")
                return
            }
            interval = setInterval(tick, 500)
        },
        stop() {
            if (interval === -1) {
                console.warn("Interval is not running")
                return
            }
            clearInterval(interval)
            interval = -1
        },
        destroy() {
            if (interval !== -1) {
                engine.stop()
            }
            stopBinding()
        },
        setActiveItem(n, previewDuration) {
            if (n >= engine.slideCount) {
                n = 0
            } else if (n < 0) {
                n = engine.slideCount - 1
            }
            options.onChange?.(n)
            engine.activeSlide = n
            engine.currentSlideRemainingDuration = typeof previewDuration === "number" ? previewDuration : engine.slideDuration
        }

    })

    let stopBinding: ReturnType<typeof watch>
    if (options.bindActiveSlide) {
        stopBinding = watch(options.bindActiveSlide, (activeSlide) => activeSlide !== undefined && engine.setActiveItem(activeSlide), {immediate: true})
    }

    return engine
}