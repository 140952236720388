import {ref} from "vue";
import { ContactMethod } from "./model/ContactModel";

const headquarters = ref({
    name: 'MKV Ozubená kola s.r.o.',
    contact: {
        methods: [
            {label: "Obchodní komunikace", value: "+420 770 142 942", type: "tel"},
            {label: "Technické dotazy", value: "+420 723 540 478", type: "tel"},
            {label: "E-mail", value: "info@mkv.cz", type: "mail"},
        ] as ContactMethod[],
    },
    address: {
        street: 'Lochotínská',
        number: '13',
        zip: '301 66',
        city: 'Plzeň',
        country: 'Česká Republika',
    },
    mapLocation: {
        bbox: '13.375052511692049,49.750881817020385,13.378499150276186,49.75237564695982',
        marker: '49.75162873774124,13.376775830984116',
    },
})
const branch = ref({
    address: {
        street: 'Jiráskova',
        number: '400',
        zip: '388 01',
        city: 'Blatná',
        country: 'Česká Republika',
    },

    mapLocation: {
        bbox: '13.878226876258852,49.41821841319406,13.881767392158508,49.419725953250236',
        marker: '49.4186895244363,13.879519701004028',
    },
})



export default {
    headquarters,
    branch,
}
