<template>
  <nav class="breadcrumbs">
    <div class="crumb" v-for="(crumb, i) in crumbs" :key="i">
      <router-link :to="crumb.to">{{ crumb.text }}</router-link>
    </div>
  </nav>
</template>

<script lang="ts">

import {defineComponent} from "vue";

export default defineComponent({
  props: {
    crumbs: {type: Array, required: true},
  }
})
</script>
