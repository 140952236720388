import { PropType, VNode, defineComponent, h } from 'vue';
import { ContactMethod } from '@/modules/Directory/model/ContactModel';

export default defineComponent({
    props: {
        methods: {type: Array as PropType<ContactMethod[]>, required: true},
    },

    setup(props) {
        return () => {
            const elements = props.methods
                .map((method) => {
                    let content: string | VNode = method.value
                    if (method.type === 'tel') {
                        content = h('a', {href: 'tel:' + method.value}, content)
                    }
                    if (method.type === 'mail') {
                        content = h('a', {href: 'mailto:' + method.value}, content)
                    }

                    return [
                        h('dt', method.label),
                        h('dd', content),
                    ]
                })

            return h('dl', elements.flat())
        }
    },
})