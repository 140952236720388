import {inject, provide, ref} from "vue"


type Translator = (placeholder: string) => string
type LocaleDictionary = { [placeholder: string]: string }

interface AppLocalization {
    t: Translator,

    loadLocale(code: string): Promise<void>|undefined,
}

type AppLocale = {
    dictionary: LocaleDictionary,
}

export function createLocalization(): AppLocalization {
    const locales: { [code: string]: AppLocale } = {}
    let activeLocaleCode: string | undefined
    const activeLocale = ref<AppLocale|undefined>(undefined)

    const switchLocale = (code: string) => activeLocale.value = locales[activeLocaleCode = code]

    return {
        t(placeholder) {
            const message = activeLocale.value?.dictionary[placeholder]

            if (!message) {
                console.warn(`t('${placeholder}') does not exist in locale '${activeLocaleCode}'`)
                return placeholder
            }

            return message
        },
        loadLocale(code) {
            if (code === activeLocaleCode) {
                return
            }

            if (locales[code]) {
                switchLocale(code)
                return
            }

            return fetch('/i18n/' + code + '.json')
                .then((res) => res.json())
                .then((dictionary) => {
                    locales[code] = {dictionary}
                })
                .then(() => switchLocale(code) && undefined)
        },
    }
}

export const provideLocalization = (translator: AppLocalization) => provide('i18n.appLocalization', translator)
export const useLocalization = (): AppLocalization => inject('i18n.appLocalization') as AppLocalization
export const useTranslator = (): Translator => useLocalization().t

