<template>
  <div class="carousel">
    <div class="content" ref="contentEl">
      <slot/>
    </div>

    <nav class="position-bar overlay" v-if="engine.slideCount > 0">
      <div v-for="(_, i) in engine.slideCount" :key="i" :class="['position', engine.activeSlide === i && 'active']"
           @click="engine.setActiveItem(i, engine.slideDuration * 1.5)"
      ></div>
    </nav>

    <div class="overlay overlay-text" v-if="$slots.overlay">
      <slot name="overlay"/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onBeforeUnmount, onMounted, ref} from "vue";
import {useCarouselEngine} from "@/modules/App/components/CarouselEngine";

export default defineComponent({
  props: {
    modelValue: Number,
  },
  setup(props, {emit}) {
    const contentEl = ref<HTMLDivElement|null>(null)

    const updateActiveSlide = (activeSlide: number) => {
      emit('update:modelValue', activeSlide)
      updateActiveSlideClass(activeSlide)
    }
    const updateActiveSlideClass = (activeSlide: number) => {
      contentEl.value!.querySelectorAll('.slide').forEach((slide, i) => {
        if (i === activeSlide) {
          slide.classList.add('active')
        } else {
          slide.classList.remove('active')
        }
      })
    }

    const engine = useCarouselEngine({
      slideDuration: 3500,
      bindActiveSlide: () => props.modelValue,
      onChange: updateActiveSlide,
    })

    onMounted(() => {
      engine.slideCount = contentEl.value?.children.length ?? -1
      engine.start()
    })

    onBeforeUnmount(() => {
      engine.destroy()
    })

    return {
      contentEl,
      engine,
    }
  }
})
</script>

<style lang="scss">
@import "../../App/sass/mixins/breakpoints";

.carousel {
  display: grid;

  > * {
    grid-column: 1; grid-row: 1;
  }

  .content {
    display: grid;
    grid-template: 'slide';

    pointer-events: none;
  }

  .slide {
    grid-area: slide;

    width: 100%; height: 100%;

    background: var(--bg);
    background-position: center;
    background-size: cover;

    transition: opacity 0.3s ease;

    &:not(.active) {
      opacity: 0;
    }
  }

  .position-bar {
    --bg-color: #{rgba(#000, 0.8)};

    place-self: end start;
    padding: 0.5em; margin: 0.25em;

    display: grid;
    grid-auto-flow: column;
    gap: 1em;

    .position {
      display: inline-block;
      width: 0.75em; height: 0.75em;
      border-radius: 25%;

      background: var(--neutral-800);
      cursor: pointer;

      transition: background 0.3s ease;

      &.active {
        background: var(--primary-500);
      }
    }
  }

  .overlay-text {
    --bg-color: #{rgba(#000, 0.8)};
    padding: 0.5em;
    border-radius: 0;

    @include sm() {
      grid-row: 2;
    }

    @include md() {
      place-self: end;
      padding: 0.25em;
    }
  }

  // Transitioning bg images through opacity causes weird overlay stuff
  .overlay {
    z-index: 1;
  }
}
</style>
