<template>
  <div class="page-home">
    <Carousel>
      <template #default>
        <div class="slide" style="--bg: url('/home/home_slide_1.jpg')"></div>
        <div class="slide" style="--bg: url('/home/home_slide_2.jpg')"></div>
        <div class="slide" style="--bg: url('/home/home_slide_3.jpg')"></div>
      </template>

      <template #overlay>
        <div class="carousel-motto">
          <span>{{ t('home.title') }}</span>
          <small>{{ t('home.subtitle') }}</small>
        </div>
      </template>
    </Carousel>

    <div class="hp-intro mt-4">
      <div class="intro-text">
        <strong>{{ t('home.listHeading') }}</strong>
        <ul class="mb-2">
          <li v-for="i in 5" :key="i">{{ t('home.list.' + i) }}</li>
        </ul>

        <router-link :to="{name: 'Page', params: {page: 'sluzby-a-produkty'}}" class="btn btn-pill">{{ t('home.cta') }}</router-link>
      </div>


      <img loading="lazy" src="../assets/hp_banner.jpg" alt="Kružná převodovka" width="221" height="236">
    </div>
  </div>
</template>

<script>
import Carousel from "@/modules/App/components/Carousel";
import {useTranslator} from "@/modules/App/i18n";

export default {
  components: {
    Carousel,
  },
  setup() {
    const t = useTranslator()

    return {
      t,
    }
  },
}
</script>

<style lang="scss">
@import "../sass/mixins/breakpoints";

.page-home {
  .carousel {
    flex: 1;
    min-height: 300px;

    @include sm() {
      .position-bar {
        place-self: start end;
        grid-auto-flow: row;
      }
    }
  }

  .carousel-motto {
    display: flex;
    flex-direction: column;

    font-size: 1.4em;

    small {
      margin-block-start: 0.25em;
      font-size: 0.65em;
      line-height: 1.25;
    }

    @include md() {
      width: 310px;
    }
  }

  .hp-intro {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 0.2em;
  }
}
</style>
