

import {defineComponent, PropType} from "vue";
import {NavLink} from "../App/navigationStore";
import NavBranch from "@/modules/Navigation/NavBranch.vue";
import {AppPage} from "@/modules/App/appPages"

export default defineComponent({
  inheritAttrs: false,
  components: {NavBranch},
  props: {
    links: {type: Array as PropType<NavLink[]>},
    toggleCaption: {type: String},
    currentPage: {type: Object as PropType<AppPage>},
  },
  data: () => ({
    open: false,
  }),
})

