
import {defineComponent, PropType, ref} from "vue";
import Breadcrumbs from "@/modules/App/Layout/Breadcrumbs.vue";
import ContactBar from "@/modules/App/Layout/ContactBar.vue";
import companyStore from "@/modules/Directory/companyStore";
import {NavLink} from "@/modules/App/navigationStore";
import NavTree from "@/modules/Navigation/NavTree.vue";
import {LocaleTarget} from "@/modules/App/appLocalization";
import {useTranslator} from "@/modules/App/i18n"
import {AppPage} from "@/modules/App/appPages"

export default defineComponent({
  emits: {
    openContactForm: () => true,
  },
  components: {
    NavTree,
    ContactBar,
    Breadcrumbs,
  },
  inheritAttrs: false,
  props: {
    navLinks: {type: Array as PropType<NavLink[]>},
    localeTarget: {type: Object as PropType<LocaleTarget>},
    footerLinks: {type: Array as PropType<NavLink[]>},

    breadCrumbs: {type: Array},
    pageTitle: String,
    currentPage: {type: Object as PropType<AppPage>},
  },
  setup() {
    const t = useTranslator()

    const copyright = ref({
      year: (new Date()).getFullYear(),
      holder: companyStore.headquarters.value.name,
    })

    return {
      copyright,

      t,
    }
  },
})
