import Home from "./pages/Home.vue"

export type AppPage = {
    name: string,
    class?: string,
    parent?: string,
    titles: { [locale: string]: string },
    slugs: { [locale: string]: string },
    version?: string,
    fullPaths?: { [locale: string]: string },
    component?: any,
}

const pages: AppPage[] = [
    {
        name: 'home',
        titles: {},
        slugs: {},
        component: Home,
    },
    {
        name: 'offer',
        titles: {cs: 'Služby a produkty', en: 'Services and products'},
        slugs: {cs: 'sluzby-a-produkty', en: 'services-and-products'},
    },
    {
        name: 'offer.front-gearbox',
        parent: 'offer',
        titles: {cs: 'Čelní a kuželočelní převodovky', en: 'Spur and bevel-spur gear boxes'},
        slugs: {cs: 'celni-a-kuzelocelni-prevodovky', en: 'spur-and-bevel-spur-gear-boxes'},
    },
    {
        name: 'offer.custom-gearbox',
        parent: 'offer',
        titles: {cs: 'Převodovky dle požadavků zákazníka', en: 'Custom gear boxes'},
        slugs: {cs: 'prevodovky-dle-pozadavku-zakaznika', en: 'custom-gear-boxes'},
    },
    {
        name: 'offer.maintenance-and-repairs',
        parent: 'offer',
        titles: {cs: 'Údržba a opravy', en: 'Maintanance and repairs of gear boxes'},
        slugs: {cs: 'udrzba-a-opravy', en: 'maintanance-and-repairs-of-gear-boxes'},
    },
    {
        name: 'offer.spare-parts',
        parent: 'offer',
        titles: {cs: 'Náhradní díly', en: 'Spare part deliveries'},
        slugs: {cs: 'nahradni-dily', en: 'spare-part-deliveries'},
    },
    {
        name: 'about-us',
        titles: {cs: 'O společnosti', en: 'About us'},
        slugs: {cs: 'o-spolecnosti', en: 'about-us'},
    },
    {
        name: 'manufacture',
        titles: {cs: 'Výroba', en: 'Manufacture'},
        slugs: {cs: 'vyroba', en: 'manufacture'},
    },
    {
        name: 'references',
        class: 'page-references',
        titles: {cs: 'Reference', en: 'References'},
        slugs: {cs: 'reference', en: 'references'},
    },
    {
        name: 'downloads', version: '2021-11',
        titles: {cs: 'Ke stažení', en: 'Download'},
        slugs: {cs: 'ke-stazeni', en: 'download'},
    },
    {
        name: 'contact',
        titles: {cs: 'Kontakt', en: 'Contact'},
        slugs: {cs: 'kontakt', en: 'contact'},
        component: () => import("./pages/Contact.vue"),
    },
]

const findPageByName = (name: string): AppPage|undefined => pages.find((page) => page.name === name)

const findPage = (locale: string, path: string): AppPage | undefined => {
    return pages.find((page) => page.fullPaths![locale] === path)
}

const fetchPage = (locale: string, page: string|AppPage) => {
    const fullPagePath = typeof page === "string" ? page : page.fullPaths![locale]

    const url = new URL(window.location.toString())
    url.pathname = `/pages/${locale}/${fullPagePath}.html`
    if (typeof page === 'object' && page.version) {
        url.searchParams.set('v', page.version)
    }

    return fetch(url.toString())
        .then((response) => response.text())
        .then((content) => {
            if (content.startsWith('<!DOCTYPE html>')) {
                throw new Error(`Page ${locale}:${page} not found`)
            }
            return content
        })
}

export default {
    findPage,
    findPageByName,

    fetchPage,
}

export const appPageIndex: { [name: string]: AppPage } = Object.fromEntries(pages.map((page) => [page.name, page]))


pages.forEach((page) => {
    const fullPaths: {[locale: string]: string} = {}
    Object.keys((page.slugs)).forEach((locale) => {
        let fullPath = page.slugs[locale]
        if (page.parent) {
            const parent = appPageIndex[page.parent]
            if (!parent) {
                throw new Error(`Parent page '${page.parent}' does not exist`)
            }
            if (!parent.fullPaths) {
                throw new Error(`Parent page '${page.parent}' is not initialized yet`)
            }
            fullPath = parent.fullPaths![locale] + '/' + fullPath
        }

        fullPaths[locale] = fullPath
    })

    page.fullPaths = fullPaths
})
