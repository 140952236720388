import {RouteLocationRaw} from "vue-router";
import {inject, provide} from "vue";

export type RouteNavEntry = {text: String, to: RouteLocationRaw}
type RouteGenerator<T=string> = (page: T) => RouteNavEntry


export const useRouteGenerator = () => {
    const generator = inject<RouteGenerator>('nav.routeGenerator')!;
    if (!generator) {
        throw new Error("No 'nav.routeGenerator' is provided")
    }
    return generator;
}
export const provideRouteGenerator = (generator: RouteGenerator) => provide('nav.routeGenerator', generator)