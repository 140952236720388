import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Page from "@/modules/App/pages/Page"
import appPages from "@/modules/App/appPages"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:locale(\\w{2})?',
    name: 'Home',
    component: Page,
    meta: {
      siteNavigation: false,
    },
    props: () => ({
      page: '@home',
    }),
  },
  {
    path: '/:locale(\\w{2})?/:page(.{3,})',
    name: 'Page',
    component: Page,
    props: (match) => ({
      page: match.params.page,
    }),
    meta: {
      title: (props: any) => appPages.findPage(props.locale || 'cs', props.page)?.titles[props.locale || 'cs'],
    },
    beforeEnter(to, from, next) {
      if (to.path.charAt(to.path.length - 1) === '/') {
        next(to.path.substr(0, to.path.length - 1))
        return
      }
      next()
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
