import {ref} from "vue";

export type NavLink = {
    page: string,
    subMenu?: NavLink[]
}

export const topMenuLinks = ref<NavLink[]>([
    {
        page: 'offer',
        subMenu: [
            {page: 'offer.front-gearbox'},
            {page: 'offer.custom-gearbox'},
            {page: 'offer.maintenance-and-repairs'},
            {page: 'offer.spare-parts'},
        ],
    },
    {page: 'about-us'},
    {page: 'manufacture'},
    {page: 'references'},
    {page: 'downloads'},
    {page: 'contact'},
]);

export const bottomMenuLinks = ref<NavLink[]>([
    {page: 'offer'},
    {page: 'about-us'},
    {page: 'manufacture'},
    {page: 'contact'},
    {page: 'downloads'},
])