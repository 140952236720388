<template>
  <iframe :src="src" :style="{
    border: '1px solid black',
    width: '100%', aspectRatio,
  }"></iframe>
</template>

<script lang="ts">

import {computed, defineComponent} from "vue"

export default defineComponent({
  props: {
    bbox: {type: String, required: true},
    marker: String,
    layer: {type: String, default: 'mapnik'},
    aspectRatio: {type: Number, default: 4/3},
  },
  setup(props) {
    const src = computed(() => {
      let url = new URL('https://www.openstreetmap.org/export/embed.html')
      url.searchParams.append('bbox', props.bbox)
      props.marker && url.searchParams.append('marker', props.marker)
      url.searchParams.append('layer', props.layer)

      return url.toString()
    })

    return {
      src,
    }
  },
})

</script>