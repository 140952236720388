import { createApp } from 'vue'
import App from './modules/App/App.vue'
import router from './router'

import "fork-awesome/css/fork-awesome.min.css"
import "./modules/App/sass/mkv.scss"

createApp(App)
    .use(router)
    .mount('#app')
