<template>
  <div class="title">{{ t('common.contacts') }}</div>
  <ContactLocation :title="hq.name" :address="hq.address"/>
  <div class="contact-box">
    <ContactDl :methods="hq.contact.methods" class="def-grid lg-single-col"/>
  </div>


  <div class="sponsor">
    <div class="general">
      <img loading="lazy" src="/images/eu-logo.svg" width="80" height="53" alt="Logo evropského fondu">

      <span>EVROPSKÁ UNIE</span>
      <span>Evropský fond pro regionální rozvoj</span>
    </div>
    <div class="details">
      <small>Operační program Podnikání a inovace pro konkurenceschopnost</small>
      <a href="/data/prilohy/o_dotacich.pdf" target="_blank" rel="noopener">Zobrazit projekty</a>
    </div>
  </div>

</template>
<script>
import {defineComponent} from "vue";
import ContactLocation from "@/modules/App/components/ContactLocation";
import companyStore from "@/modules/Directory/companyStore";
import {useTranslator} from "@/modules/App/i18n";
import ContactDl from "../components/ContactDl";

export default defineComponent({
  emits: {
    openContactForm: () => true,
  },
  components: {
    ContactLocation,
    ContactDl
},
  setup() {
    const t = useTranslator()

    return {
      hq: companyStore.headquarters,

      t,
    }
  },
})
</script>
