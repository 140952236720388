
import {defineComponent, onBeforeUnmount, onMounted, ref} from "vue";
import {useCarouselEngine} from "@/modules/App/components/CarouselEngine";

export default defineComponent({
  props: {
    modelValue: Number,
  },
  setup(props, {emit}) {
    const contentEl = ref<HTMLDivElement|null>(null)

    const updateActiveSlide = (activeSlide: number) => {
      emit('update:modelValue', activeSlide)
      updateActiveSlideClass(activeSlide)
    }
    const updateActiveSlideClass = (activeSlide: number) => {
      contentEl.value!.querySelectorAll('.slide').forEach((slide, i) => {
        if (i === activeSlide) {
          slide.classList.add('active')
        } else {
          slide.classList.remove('active')
        }
      })
    }

    const engine = useCarouselEngine({
      slideDuration: 3500,
      bindActiveSlide: () => props.modelValue,
      onChange: updateActiveSlide,
    })

    onMounted(() => {
      engine.slideCount = contentEl.value?.children.length ?? -1
      engine.start()
    })

    onBeforeUnmount(() => {
      engine.destroy()
    })

    return {
      contentEl,
      engine,
    }
  }
})
