
import {computed, defineComponent, PropType, ref} from "vue";
import {RouteNavEntry, useRouteGenerator} from "@/modules/Navigation/navRouting";
import {NavLink} from "@/modules/App/navigationStore";
import {AppPage} from "@/modules/App/appPages"

export default defineComponent({
  name: 'NavBranch',
  props: {
    link: {type: Object as PropType<NavLink>, required: true},
    currentPage: {type: Object as PropType<AppPage>},
  },
  setup(props) {
    const locationGenerator = useRouteGenerator()

    const hasSubmenu = computed(() => props.link.subMenu && props.link.subMenu.length > 0)
    const childActive = computed(() => {
      return !!props.link.subMenu
          ?.some((item) => props.currentPage?.name && item.page.startsWith(props.currentPage.name))
    })
    const navLink = computed<RouteNavEntry|undefined>(() => props.link.page ? locationGenerator(props.link.page) : undefined)
    const open = ref(false)

    return {
      hasSubmenu,
      childActive,

      navLink,

      open,
    }
  },
})
